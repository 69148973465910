import classNames from 'classnames';
import React, { forwardRef, useCallback, useState } from 'react';
import { Page } from 'react-pdf';
import PlaybackPreparingLoader from '@pages/MediaViewer/AssetViewer/PlaybackPreparingLoader';

import './PageThumbnail.less';

interface PageThumbnailProps {
  maxWidth: number;
  maxHeight: number;
  page: number;
  active: boolean;
  onClick: (page: number) => void;
}

const PageThumbnail = forwardRef<HTMLDivElement, PageThumbnailProps>(
  function PageThumbnail({ maxWidth, maxHeight, page, active, onClick }, ref) {
    const [pageSize, setPageSize] = useState<{
      width: number;
      height: number;
    }>({
      width: 0,
      height: 0
    });

    const onLoadSuccess = useCallback(
      (pdfPage: {
        rotate: number;
        originalWidth: number;
        originalHeight: number;
      }) => {
        let { originalWidth, originalHeight } = pdfPage;
        if (pdfPage.rotate % 180 !== 0) {
          const tmp = originalWidth;
          originalWidth = originalHeight;
          originalHeight = tmp;
        }
        const scaleX = maxWidth / originalWidth;
        const scaleY = maxHeight / originalHeight;
        const scale = Math.min(scaleX, scaleY);
        setPageSize({
          width: originalWidth * scale,
          height: originalHeight * scale
        });
      },
      [setPageSize]
    );

    return (
      <div className="page-thumbnail" ref={ref}>
        <div
          className={classNames({
            'page-thumbnail-item': true,
            active
          })}
        >
          <Page
            width={
              pageSize.width > pageSize.height ? pageSize.width : undefined
            }
            height={
              pageSize.height > pageSize.width ? pageSize.height : undefined
            }
            pageNumber={page}
            className="page-thumbnail-page"
            renderAnnotationLayer={false}
            renderTextLayer={false}
            onLoadSuccess={onLoadSuccess}
            loading={
              <div style={{ height: '147px' }}>
                <PlaybackPreparingLoader />
              </div>
            }
          />
          <div className="page-thumbnail-link" onClick={() => onClick(page)} />
          <div className="page-thumbnail-number">{page}</div>
        </div>
      </div>
    );
  }
);

export default PageThumbnail;
